/* @media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    height: 100vh;
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 300px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 0px;
    padding: 10px 15px;
}
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto; 
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}
 */
 @media (min-width: 576px) {
  .ms-sm-2{
    margin-left: 8px;
  }
  .d-sm-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 0px;
    padding: 10px 15px;
}
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}
@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    height: 100vh;
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 300px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
  .ant-modal-body .ant-form-item .ant-row.ant-form-item-row {
    margin: 0 !important;
  }
  .footer-menu ul {
    justify-content: center;
  }
  footer.ant-layout-footer {
    padding: 0px 15px;
    text-align: center;
  }
  .project-ant {
    display: block;
  }

  .ant-filtertabs {
    text-align: right;
  }
  .rightside-top span.ant-input-affix-wrapper {
    width: 200px;
  }

  .rightside-top {
    justify-content: flex-end;
  }

  header.ant-layout-header.subheader {
    padding: 0px 24px 0px 0px;
  }

  main.ant-layout-content.content-ant {
    padding-left: 0px !important;
  }

  .ant-row {
    margin-left: -12px;
    margin-right: -12px;
  }
  span.ismobile {
    display: inline-block;
  }
  .bar-chart {
    width: 100% !important;
    max-width: 100%;
  }
  .full-width {
    max-width: 100% !important;
  }
  .bar-chart {
    width: 100% !important;
  }

  .icon-move-right {
    margin-bottom: 15px;
  }
  .pageheader {
    display: block;
  }
  .ant-cret img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
  .ant-modal-body form#create .ant-row {
      margin: 0 !important;
  }
} 
@media (max-width:1599.98px) {
    :root {
      --contactWidth: 400px;
  }
}
@media (max-width:1391.98px) {
  .ant-card-head-wrapper .ant-card-extra {
      gap: 5px !important;
  }
  .ant-card-head-wrapper .ant-card-extra .ant-btn {
      padding: 0 10px !important;
      font-size: 12px;
  }
}
@media (max-width:1480.98px) {
  .customerMain .ant-card-extra, .specialistMain .ant-card-extra{
    flex-direction: column;
    align-items: unset;
    
  }
  .customerMain .ant-card-extra .button_group,  .specialistMain .ant-card-extra .button_group{
    justify-content: end;
    
  }
}
@media (max-width:1199.98px){
  :root {
    --contactWidth: 300px;
}
  .ant-card-head-wrapper {
      flex-direction: column !important;
  }
  .tablespace .ant-card-head-title {
      width: 100%;
      font-size: 16px;
      padding-bottom: 0 !important;
      line-height: 1.5;
  }
  .ant-card-head-wrapper .ant-card-extra{
    padding-top: 0 !important;
  }
  
  .vehicleMake .ant-card-head-wrapper .ant-card-extra, .vehicleModal .ant-card-head-wrapper .ant-card-extra,
  .vehicleType .ant-card-head-wrapper .ant-card-extra, .categoryService .ant-card-head-wrapper .ant-card-extra, .brandManager .ant-card-head-wrapper .ant-card-extra,
  .productAttribute .ant-card-head-wrapper .ant-card-extra, .productCaralogue .ant-card-head-wrapper .ant-card-extra, .quoteManagement .ant-card-head-wrapper .ant-card-extra,
  .blog .ant-card-head-wrapper .ant-card-extra, .bannerMain .ant-card-head-wrapper .ant-card-extra {
    flex-direction: row;
  }
  .ant-card-head-wrapper .ant-card-extra {
    flex-direction: column;
    gap: 12px;
    align-items: end;
  }
}
@media (max-width: 991.98px) {
  .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler svg path {
      fill: #000 !important;
  }
  .layout-dashboard .ant-layout-header {
      margin: 12px 0;
      border-bottom: solid 1px #c7c7c7;
      border-radius: 0;
      padding-bottom: 12px;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }
  .edit-page-wrap .ant-row.ant-form-item-row {
      margin: 0;
  }
  .edit-page-wrap .ant-row {
      margin: 0;
  }
  .ant-form-item.add-types-cls {
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }
  .ant-form-item.add-types-cls {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
.layout-dashboard .header-control {
    padding-right: 0 !important;
}
.layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler {
    padding-right: 0 !important;
}
.layout-dashboard .ant-row {
    padding: 0 !important;
    margin: 0 !important;
}
footer.ant-layout-footer {
  width: 100%;
}
.selectQuote {
  flex-direction: column;
}
}
@media (max-width: 767.98px) {
  .add-btn {
    padding: 0;
}
  .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .ant-btn.ant-btn-dashed.ant-btn-block {
      padding: 10px 10px;
      width: 100%;
  }
  .ant-form-item.add-types-cls {
      margin: 0 0 15px 0 !important;
  }
  .ant-space-item {
      margin: 0 !important;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-row {
      margin: 0;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline {
      padding: 0;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .anticon[tabindex] {
    right: 0;
  }  
  .event_card_info {
        flex-direction: column;
    }

    .event_card_info .event-cont-dtl {
        margin-left: 0;
    }

    .event_card_info .event-cont-dtl .view-inner-cls.desc-wrap {
        flex-wrap: wrap;
    }
    .event-img-wrap {
       height: unset;
        flex: 0 0 100%;
    }
    .layout-dashboard.layout-dashboard-rtl .header-control {
      justify-content: flex-end;
    }

    .ant-card-head-wrapper .ant-card-extra {
      padding-top: 0 !important;
      flex-wrap: wrap;
      justify-content: start;
      /* align-items: start; */
    }
    .ant-card-head-wrapper .ant-card-head-title {
        padding-bottom: 0 !important;
        width: 100%;
    }
/* 01/06/2023 */
    .layout-dashboard .header-control {
      margin-top: 0;
    }
    .layout-dashboard .header-control {
        margin-top: 0;
    }
    .ant-row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    /* .ant-col {
        padding: 0 !important;
    } */

    .view-main-list {
      padding-right: 0;
      border-right: 0;
      margin-bottom: 15px;
    }
    .layout-dashboard .header-control svg {
        margin-right: 0 !important;
    }
    .ant-btn.ant-btn-link.sidebar-toggler {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
    .ant-card-body {
        padding: 12px !important;
    }
    .ant-modal {
        top: 50px !important;
    }
    .card-profile-head .ant-card-head {
        padding: 0 10px !important;
    }
    .card-profile-head .ant-card-head-wrapper .ant-avatar-group .avatar-info h4 {
        font-size: 14px;
    }
    .card-profile-head .ant-card-head-wrapper .ant-avatar-group .avatar-info p {
        font-size: 12px;
    }
    .brandManager .ant-card-head-wrapper .ant-card-extra,
    .productAttribute .ant-card-head-wrapper .ant-card-extra,
    .categoryService .ant-card-head-wrapper .ant-card-extra,
    .quoteManagement .ant-card-head-wrapper .ant-card-extra,
    .blog .ant-card-head-wrapper .ant-card-extra {
      flex-wrap: nowrap;
    }
    .backArrowBtn {
      display: block;
  }
  .chatMainOuter {
    position: relative;
  }
  :root {
    --contactWidth: 100%;
}
  .chatMainOuter .chatingOuter {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    transition: ease-in-out .3s;
    opacity: 1;
  }
  .chatMainOuter .chatingOuter:not(.active) {
    right: -100%;
    opacity: 0;
  }
  
  .chatMainOuter .contactOuter .contactBody, .chatMainOuter .chatingOuter .chatingBody {
      height: 0;
  }
}
@media (max-width:575.98px){
  .ant-card-head-wrapper .ant-card-extra button.ant-btn + span {
    width: 100%;
    display: block;
  }
  .ant-card-head-wrapper .ant-card-extra button.ant-btn + span .ant-upload.ant-upload-select.ant-upload-select-picture {
    width: 100%;
  }
  .ant-form-item.add-types-cls {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .ant-card-head-wrapper .ant-card-extra .ant-btn {
      width: 100%;
  }

  span.ant-input-group-wrapper.ant-input-search.ant-input-search-large.ant-input-group-wrapper-lg span.ant-input-wrapper.ant-input-group {
      max-width: 100%;
  }

  span.ant-input-group-wrapper.ant-input-search.ant-input-search-large.ant-input-group-wrapper-lg span.ant-input-wrapper.ant-input-group button.ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
      min-width: 50px;
  }
  .view-inner-cls h6 {
      font-size: 11px;
  }
  .view-inner-cls h5 {
      font-size: 12px;
  }
  .signin-box h5 {
      font-size: 14px !important;
      margin-bottom: 10px !important;
  }
  .signin-box h1 {
      font-size: 16px !important;
  }
  .ant-btn {
      padding: 5px 10px;
      font-size: 12px;
  }
  th, td {
      padding: 5px 3px !important;
      font-size: 12px;
  }
  .assignment-chart-list .ant-card.card-project p {
      font-size: 12px;
      padding: 0 10px;
  }
  .ant-breadcrumb-separator {
      margin: 0 3px;
  }
  span.ant-breadcrumb-link {
      font-size: 12px;
  }
  .ant-input {
    font-size: 12px;
  }
  .breadcrumbMain {
    display: none !important;
  }
  .header-control {
    justify-content: space-between !important;
  }
  .header-control .profileDropdownMain .ant-btn {
    order: 2 !important;
    text-align: left !important;
  }
  .ant-picker.ant-picker-range, .ant-picker.ant-picker-range.ant-picker-focused {
    width: 100%;
}
.searchOuter {
  flex-direction: column;
}
.ant-picker .ant-picker-input > input {
  width: 100%;
}
.mt-xs-2 {
  margin-top: 8px;
}
.brandManager .ant-card-head-wrapper .ant-card-extra,
.categoryService .ant-card-head-wrapper .ant-card-extra {
  flex-wrap: wrap;
}

.view-inner-cls.other-image.productDetail .ant-image {
  width: 60px !important;
  height: 60px !important;
}
.logoOuter {
  height: 140px;
  width: 100px;
}
}
@media (max-width: 434px){
  .productAttribute .ant-card-head-wrapper .ant-card-extra,
  .quoteManagement .ant-card-head-wrapper .ant-card-extra,
  .blog .ant-card-head-wrapper .ant-card-extra {
    flex-wrap: wrap;
  }
  .w-xxs-100 {
    width: 100%;
  }
}
@media (max-width: 360px){
  .button_group {
    flex-direction: column;
    width: 100%;
  }
}

